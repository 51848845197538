import Main from "../Structure/Main";
import Ep from "../Structure/Ep";
import Animeover from "../Structure/Animeover";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";

export default function Danda() {
  return (
    <>
      <ScrollToTop />
      <Main
        img="https://m.media-amazon.com/images/M/MV5BYWFhOWMxNTYtZThiMi00ZmQ5LTlmODktN2QwNzUyZjMyZGQzXkEyXkFqcGc@._V1_FMjpg_UX1000_.jpg"
        name="DAN DA DAN"
        s="1"
        e="12"
        videotri="https://youtu.be/0XJxfbN36Uw?si=VnfToKdbFib8XLnm"
      />
      <Ep
        ep="S1-E1 DAN DA DAN"
        s="S1-E1"
        link="https://drive.google.com/file/d/18h576uR9NL7RVi0z6seZ0sUpQk90dclU/view?usp=sharing"
      />

      <Animeover />
      <Footer />
    </>
  );
}
