import React from "react";
import { FaYoutube } from "react-icons/fa";

import No from "./No";

export default function Main(props) {
  return (
    <>
      <div className="mt-7 mb-5 ml-40  max-md:ml-0 flex justify-center">
        <div className="main h-113 w-113 bg-whitelite backdrop-blur rounded-lg border border-whitegood shadow shadow-black max-md:h-114 max-md:w- max-md:ml-0">
          <div className="main-img flex max-md:justify-center">
            <img
              className="h-80 w-auto mt-12 ml-12 rounded-md transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 max-md:ml-4"
              src={props.img}
              alt={props.name}
            />
          </div>
          <div className="main-name flex flex-col justify-center ml-80 -mt-72 max-md:-mt-0 max-md:ml-0 ">
            <h1 className="text-2xl max-md:text-center max-md:mt-5 font-extrabold">
              {props.name}
            </h1>
            <br />
            <h2 className="max-md:text-center font-bold">
              {props.s} Seasons {props.e} Episodes
            </h2>
            <br />
            <h3 className="max-md:text-center font-bold">Language: Hindi</h3>
            <br />
            <h3 className="max-md:text-center font-bold">
              Quality: (1080p, 720p, 480p,)
            </h3>

            <ul className="flex mt-5 max-md:justify-center font-bold max-md:hidden">
              <h2 className="pr-1 ">Genres :</h2>
              <li className="">Animation</li>
              <li className="ml-2">Drama</li>
              <li className="ml-2">crunchyroll</li>
              <li className="ml-2">Hindi</li>
              <li className="ml-2">Sci-Fi</li>
              <li className="ml-2">Fantasy</li>
            </ul>

            <h3 className="pt-5 font-bold max-md:hidden">
              Hin-Anime is the best site to watch {props.name} Hindi Dubbed
              online with out ads, in FHD quality. You can also find
              AtelierPontdarc anime on Hin-Anime website.
            </h3>
            <div className="max-md:hidden">
              <a target="_blank" rel="noreferrer" href={props.videotri}>
                <button className="flex items-center justify-center mt-5 gap-3 bg-gray-800 text-white h-12 w-40 rounded-lg shadow-lg hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105">
                  <FaYoutube className="text-xl text-white" />
                  <span className="text-base font-semibold">
                    YouTube Trailer
                  </span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <No />
      <div className="mb-0.1 ml-40  max-md:ml-0 flex justify-center">
        <div className="ep-head flex items-center bg-customBg h-[150px] w-1.2 rounded-t-lg mt-3">
          <img
            src={props.img}
            alt="anime"
            className="h-[115px] w-auto pl-8 sm:pl-4"
          />
          <div className="flex flex-col ml-8">
            <h3 className="text-white text-3xl">Seasons {props.s}</h3>
            <p className="text-white mt-2 text-base">Episodes {props.e}-2023</p>
          </div>
        </div>
      </div>
    </>
  );
}
